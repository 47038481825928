import React from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

import SEO from "../components/seo";
import About from "../components/About";

export const pageQuery = graphql`
  query AboutBySlug {
    prismicAbout {
      data {
        meta_title
        meta_description {
          text
        }
        meta_image {
          url
        }

        title {
          text
        }
        bio {
          raw
        }
        image {
          ...sharp
        }
        contact {
          raw
        }
      }
    }
  }
`;

const PageAbout = ({ data }) => {
  const { meta_title, meta_description, meta_image, title, texte, images } =
    data.prismicAbout.data;

  return (
    <div className='wrapper'>
      <SEO
        pageTitle={meta_title ? meta_title : title.text}
        pageDescription={
          meta_description ? meta_description.text : "desc google here"
        }
        pageImage={
          meta_image
            ? meta_image.url
            : "https://images.prismic.io/sisto-studios/905ac41b-19ac-45f2-8da1-6235d04a746f_sisto-architecture-9.png"
        }
        template={`template-about`}
        page={true}
      />
      <div className='content px-md-'>
        <About input={data.prismicAbout.data} />
      </div>
    </div>
  );
};

export default withPreview(PageAbout);
